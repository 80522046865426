import { openPopup } from '../../module/popup'

function handleReponse(data, formBlock) {
	const submitButton = formBlock.querySelector('[type="submit"]')
	if (submitButton) submitButton.blur()
	formBlock.classList.remove('is-submitting')
	if ('error' === data.type) {
		showError(data, formBlock)
		return
	}
	showConfirmation(formBlock)
}

function showConfirmation(formBlock) {
	formBlock.classList.remove('is-submitting')
	formBlock.classList.remove('has-errors')
	formBlock.classList.add('is-submitted')
	const errorElement = formBlock.querySelector('.wp-block-blob-contact-form__errors')
	if (errorElement) {
		errorElement.innerHTML = ''
	}
	const labelElements = formBlock.querySelectorAll('label')
	if (labelElements) {
		[...labelElements].forEach(element => element.classList.remove('has-error'))
	}
}

function showError(data, formBlock) {
	formBlock.classList.remove('is-submitting')
	formBlock.classList.add('has-errors')
	const errorElement = formBlock.querySelector('.wp-block-blob-contact-form__errors')
	if (!errorElement) return
	errorElement.innerHTML = data.message
	if ( ! data.errors || 0 === data.errors.length ) return
	Object.keys(data.errors).forEach(fieldName => {
		const field = formBlock.querySelector(`[name="${fieldName}"]`)
		if (!field) return
		const label = field.closest('label')
		if (!label) return
		label.classList.add('has-error')
	})
}

document.addEventListener('DOMContentLoaded', () => {
	const contactFormBlocks = document.querySelectorAll('.wp-block-blob-contact-form')
	if (!contactFormBlocks) return
	contactFormBlocks.forEach(formBlock => {
		const form = formBlock.querySelector('form')
		if (!form) return
		form.addEventListener('submit', event => {
			event.preventDefault()
			formBlock.classList.add('is-submitting')
			fetch('/wp-admin/admin-ajax.php', { method: 'post', body: new FormData(form) })
				.catch(() => showError({ overall: 'An error occured while submitting the form. Please try again.' }, formBlock))
				.then(response => {
					if ( 200 !== response.status ) {
						return {
							type: 'error',
							message: 'An error occured while submitting the form. Please try again.'
						}
					}
					return response.json()
				})
				.then(data => handleReponse(data, formBlock))
		})
	})
})

document.addEventListener('DOMContentLoaded', () => {
	const popupButtons = document.querySelectorAll('[href="#contact-popup"]')
	if (!popupButtons) return
	[...popupButtons].forEach(popupButton => {
		popupButton.addEventListener('click', event => {
			event.preventDefault()
			event.stopPropagation()
			openPopup('contact-popup')
		})
	})
})
