export function openPopup(id) {
	const popup = document.querySelector(`#${id}`)
	if (!popup) return
	document.body.classList.add('has-popup-open')
	popup.classList.add('is-open')
}

export function closePopup() {
	const popup = document.querySelector('.popup.is-open')
	if (!popup) return
	document.body.classList.remove('has-popup-open')
	popup.classList.remove('is-open')
}

document.addEventListener(
	'click',
	event => {
		if (!event.target.matches('.popup__close')) return
		closePopup()
	}
)
